<template>
  <div class="chatbox">
    
    <ChatBox ref="chat" />
  </div>
</template>

<script>
import ChatBox from "@/components/ChatBox";
export default {
  components: { ChatBox },
  mounted() {
    // this.$refs.chat.getToken();
  },
};
</script>

