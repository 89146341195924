import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import VueRouter from 'vue-router';
import ChatPage from './pages/ChatPage.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
axios.defaults.baseURL='';
Vue.config.productionTip = false;

Vue.use({
  install(Vue){
    Vue.prototype.$axios=axios;
    Vue.prototype.$VueRouter=VueRouter;
    Vue.prototype.$uuidv4=uuidv4;
  },
});

Vue.use(VueRouter)

const routes = [
    { path: '/', component: ChatPage}
]

const router = new VueRouter({
    routes,
    mode: 'history'
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");